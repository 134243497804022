import { render, staticRenderFns } from "./downloadTrack.vue?vue&type=template&id=a5af804a&scoped=true"
import script from "./downloadTrack.vue?vue&type=script&lang=js"
export * from "./downloadTrack.vue?vue&type=script&lang=js"
import style0 from "./downloadTrack.vue?vue&type=style&index=0&id=a5af804a&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a5af804a",
  null
  
)

export default component.exports