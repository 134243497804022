<template>
	<v-dialog
		:value="downloadTrackDialogVisible"
		max-width="570"
		@input="$emit('input')">

		<div class="coming_soon_dialog style_dialog downloads_dialog">
			<h2 class="header">
                <span class="border_head" v-if="isFreeUserPlan()">Free Account</span>
                <span class="border_head" v-else>Free Downloads</span>
			</h2>
			<div class="cnt">
				<template v-if="(SONG_DOWNLOADED.limit - SONG_DOWNLOADED.downloaded) > 0">
					You have <span class="green_selected">{{ SONG_DOWNLOADED.limit - SONG_DOWNLOADED.downloaded }}</span> out of
					<span class="green_selected">{{ SONG_DOWNLOADED.limit }}</span> free Tracks downloads left today
				</template>
				<template v-else>
					<span>
                        You’ve reached your download limit for today<br>
					    <span v-if="isFreeUserPlan()">Try again tomorrow or upgrade to premium</span>
                    </span>
				</template>
			</div>
			<div v-if="DOWNLOAD_SONG_LINK_ERROR !== ''" class="message_error">{{ DOWNLOAD_SONG_LINK_ERROR }}</div>
			<div class="wrap_actions">
				<button type="button" class="is-nope" :class="{'center': !isWithinLimit() && !isFreeUserPlan()}" @click="cancel()" v-ripple>
                    <span v-if="!isWithinLimit() && !isFreeUserPlan()">Ok</span>
                    <span v-else>Cancel</span>
                </button>
				<button type="button" v-if="isWithinLimit()" class="is-nope" @click="download" v-ripple
				        :disabled="DOWNLOAD_SONG_LINK_ERROR !== ''">Download
				</button>
				<button type="button" v-else-if="isFreeUserPlan()" class="c2a_btn" @click="upgrade">
					Upgrade
					<span class="arrow">
                        <span class="line_svg"></span>
                        <svg width="7" height="10" viewBox="0 0 7 10" fill="none">
                            <path d="M1.5 1.5L5 5L1.5 8.5" stroke="white" stroke-width="2" stroke-linecap="square"/>
                        </svg>
                    </span>
				</button>
			</div>
		</div>
	</v-dialog>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
	name: "downloadTrack",
	props: ['value'],
	data() {
		return {}
	},
	computed: {
		...mapGetters(['SONG_DOWNLOADED', 'SONG_DOWNLOAD_DATA', 'DOWNLOAD_SONG_LINK', 'DOWNLOAD_SONG_LINK_ERROR', 'PROFILE']),
		downloadTrackDialogVisible: {
			get() {
				return this.value;
			},
			set(value) {
				this.$emit('input', value)
			}
		},
	},

	methods: {
		...mapActions(['GET_DOWNLOAD_SONG_LINK']),
		cancel() {
			this.downloadTrackDialogVisible = false;
		},
		download() {
            this.GET_DOWNLOAD_SONG_LINK(this.SONG_DOWNLOAD_DATA.id)
				.then(() => {
					if (this.DOWNLOAD_SONG_LINK) {
						this.downloadTrackDialogVisible = false;
						let link = document.createElement('a');
						link.href = this.DOWNLOAD_SONG_LINK;
						link.download = `${this.SONG_DOWNLOAD_DATA.artist} - ${this.SONG_DOWNLOAD_DATA.name}.flac`;
						link.click();
					}
				})
				.catch(err => {
					this.downloadTrackDialogVisible = true;
					console.error(`Error downloadTrack: ${err}`)
				})
		},
		upgrade() {
			this.$router.push({name: 'memberships'});
		},
        isFreeUserPlan() {
            return this.PROFILE === 'free';
        },
        isWithinLimit() {
            return (this.SONG_DOWNLOADED.limit - this.SONG_DOWNLOADED.downloaded) > 0
        }
	}
}
</script>

<style scoped>
button:disabled,
button[disabled] {
	border: 1px solid #999999;
	background-color: #cccccc;
	color: #666666;
	cursor: not-allowed;
	pointer-events: none;
}
button {
    &.center {
        margin: 0 auto;
    }
}
</style>
